import styled from "@emotion/styled"
import React from "react"
import leapHero from "../../images/programs/leap/hero-leap.svg"
import leapLogo from "../../images/programs/logo-leap-no-title.svg"
import { HeroContainer } from "./Hero-Container"

const Container = styled.div`
  .leap-logo {
    width: 130px;
  }
  p {
    max-width: 470px;
  }
`

export const LeapHero = () => {
  return (
    <Container>
      <HeroContainer className="container">
        <div>
          <img
            className="leap-logo"
            src={leapLogo}
            alt="animated students in classroom"
          />
          <h1>LEAP</h1>
          <p>We are building a global mindset for tomorrow’s world</p>
        </div>
        <img src={`${leapHero}`} alt="animated students in classroom" />
      </HeroContainer>
    </Container>
  )
}
