import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import redPlus from "../images/programs/leap/plus-red.svg"
import verticalImage from "../images/programs/leap/strategies-vertical.svg"
import horizontalImage from "../images/programs/leap/strategies.svg"

const MainContainer = styled.div`
  padding: 062px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.secondary201};
  h3 {
    padding: 0em;
    margin: 0;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 42px;
  }
  .main-image {
    margin-top: 30px;
    width: 100%;
  }
  .text-container {
    p + img {
      position: relative;
      top: 12px;
      width: 27px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-family: ${fonts.secondary};
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 0px;
    letter-spacing: -0.75px;
    line-height: 40px;
    text-align: center;
    color: ${colors.primary2};
  }
  @media ${device.tablet} {
    .main-image {
      margin-top: 0;
      width: 700px;
    }
    p {
      font-size: 28px;
    }
    h3 {
      text-align: center;
      font-size: 60px;
      line-height: 65px;
    }
  }
  @media ${device.laptop} {
    padding: 128px 0px;
    h3 {
      text-align: center;
      margin-bottom: 8px;
    }
    .main-image {
      width: 100%;
    }
    .text-container {
      width: 1112px;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      p {
        font-size: 24px;
      }
    }
  }
`

const Paragraphs = styled.div`
  margin-top: 50px;
  .bottom-paragraph {
    margin: 0;
    font-family: ${fonts.primary};
    color: ${colors.dark3};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    margin-top: 00px;
  }
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    .bottom-paragraph {
      text-align: left;
    }
    .bottom-paragraph:first-of-type {
      max-width: 650px;
    }
    .bottom-paragraph:last-of-type {
      position: relative;
      left: 10px;
    }
  }
`

export const LeapStrategy = () => {
  return (
    <MainContainer>
      <div className="flex container">
        <h3>LEAP's 4Cs strategy</h3>
        <div className="text-container">
          <p>Read critically</p>
          <img src={redPlus} alt="plus" />
          <p>Think creatively</p>
          <img src={redPlus} alt="plus" />
          <p>Write clearly</p>
          <img src={redPlus} alt="plus" />
          <p>Speak confidently</p>
        </div>
        <picture>
          <source media="(max-width: 619px)" srcset={verticalImage} />
          <source media="(min-width: 620px)" srcset={horizontalImage} />
          <img
            alt="animatied graphic of children"
            className="main-image"
            src={horizontalImage}
          />
        </picture>
      </div>
      <Paragraphs className="container">
        <p className="bottom-paragraph">
          LEAP's 4Cs strategy inspires students to read critically, think
          creatively, write clearly, and speak confidently. We believe
          encompassing these skills—reading, reasoning ability, and
          socio-emotional skills—is a gateway for students to become better
          thinkers, innovators, and productive citizens.
        </p>
        <p className="bottom-paragraph">
          Students enrolled in LEAP programs receive up to $100 in
          non-conditional educational grants per year to support their learning
          inside and outside the classroom.
        </p>
      </Paragraphs>
    </MainContainer>
  )
}
