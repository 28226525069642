import React, { useContext, useState } from "react"
import { LeapHero } from "../../components/heros/Leap-Hero"
import { BecomeOneOfUs } from "../../components/Become-One-Of-Us"
import { LeapPanels } from "../../components/panels/Leap-Panels"
import { LeapBio } from "../../components/programs-bio/Leap-Bio"
import { ImageContainer } from "../../components/Image-Container"
import { StaticImage } from "gatsby-plugin-image"
import { LeapStrategy } from "../../components/Leap-Strategy"
import { ProgramBluePanel } from "../../components/panels/Program-Blue-Panel"
import { Eligibility } from "../../components/Eligibility"
import { Seo } from "../../components/Seo"
import { siteContext } from "../../layout/SiteContextProvider"

const Leap = () => {
  const [hovered, setHovered] = useState(false)
  const { toolTipMobile, setToolTipMobile } = useContext(siteContext)
  return (
    <>
      <Seo
        title="LEAP - Learning Enrichment & Acceleration Program "
        description="LEAP supports low-progress learners from under-served and under-resourced public schools with the technology and human capital needed to help all children realize their potential."
      />
      {/* <Nav /> */}
      <LeapHero />
      <LeapPanels />
      <LeapBio
        toolTipMobile={toolTipMobile}
        setToolTipMobile={setToolTipMobile}
        hovered={hovered}
        setHovered={setHovered}
      />
      <ImageContainer>
        <StaticImage
          src="../../images/children-in-blue.jpeg"
          placeholder="none"
          quality={100}
          alt="school children in blue"
        />
      </ImageContainer>
      <LeapStrategy />
      <ProgramBluePanel>
        {" "}
        <StaticImage
          className="x top-image"
          src="../../images/programs/leap/scholar.png"
          placeholder="none"
          quality={100}
          alt="scholar student"
        />
        <div className="text-container">
          <p>
            "Before that program, composition, English, and reading aloud
            passages in class were my worst things to do in class... but after
            you established the program, I started enjoying reading and writing.
            I got that spirit of reading and now I was not only the best in
            English with an 85/ 100 but also the best student in Kawangware
            primary in the recent KCPE examination."
          </p>
          <p>Arnold Matiba - Student, Kawangware primary school</p>
        </div>
      </ProgramBluePanel>
      <ImageContainer>
        <StaticImage
          src="../../images/programs/leap/leap-kids-in-classroom.jpeg"
          placeholder="none"
          quality={100}
          alt="LEAP students posing and smiling"
        />
      </ImageContainer>
      <Eligibility />
      <BecomeOneOfUs />
      {/* <Footer /> */}
    </>
  )
}

export default Leap
