import styled from "@emotion/styled"
import React, { useState } from "react"
import { fonts, device, colors } from "../layout/GlobalStyles"
import qualityEducation from "../../images/programs/quality-education.svg"
import leapLogo from "../../images/programs/logo-leap.svg"
import mlifeLogo from "../../images/logos/logo-regular.svg"
import sdgLogo from "../../images/programs/logo-un.svg"
import { Tooltip } from "../Tooltip"

const tooltipText = ["Learning Enrichment and Acceleration Program."]

const BioContainer = styled.section`
  padding: 0px 0 48px;
  .line {
    width: 1px;
    height: 80px;
    background: black;
  }
  @media ${device.laptop} {
    padding-bottom: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LeftPanel = styled.div`
  padding: 58px 0px;
  h2 {
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    margin: 0em 0px 10px 00px;
    line-height: 65px;
  }
  p {
    margin: 0 0px 30px 0px;
    color: ${colors.primary2};
    font-size: 40px;
    letter-spacing: -1.25px;
    line-height: 48px;
    font-family: ${fonts.secondary};
  }
  span {
    font-weight: 500;
    position: relative;
    top: 10px;
    font-size: 18px;
    letter-spacing: -0.56px;
    line-height: 27px;
    font-family: ${fonts.primary};
  }
  div {
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      width: 1px;
      height: 80px;
      position: relative;
      top: 10px;

      background: black;
    }
    .logo {
      width: 140.3px;
    }
  }
  @media ${device.laptop} {
    h2 + p {
      width: 540px;
    }
    div {
      margin: -5px 0px 22px;
    }
    p + div {
      width: 363px;
    }
    min-width: 463px;
    margin-bottom: 130px;
  }
  @media ${device.laptopL} {
    p {
      max-width: 458px;
    }
  }
`

const RightPanel = styled.div`
  div {
    display: flex;
  }
  .reg-p {
    font-size: 22px;
  }
  .line {
    height: 80px;
    width: 1px;
    background: ${colors.black};
  }
  .logo-container {
    justify-content: space-between;
  }
  @media ${device.laptop} {
    .ok {
      width: 326px;
      margin-top: 0px;
    }
    position: relative;
    top: 0px;
    margin-top: 125px;
    max-width: 553px;
    margin-bottom: 30px;
  }
  .first-par {
    margin-top: 50px;
  }
  p + p {
    margin-top: 40px;
  }
`

export const LeapBio = ({
  setToolTipMobile,
  toolTipMobile,
  hovered,
  setHovered,
}) => {
  const [name, setName] = useState("")
  const hoverAction = (bool, name) => {
    setName(name)
    setHovered(bool)
  }
  return (
    <BioContainer className="container">
      <LeftPanel>
        <h2>Our Goal</h2>
        <p>End learning poverty. </p>
        <div>
          <img className="logo" src={mlifeLogo} alt="mlife logo" />
          <div></div>
          <img src={sdgLogo} alt="SDG logo" />
        </div>
        <span>MLIFE support(s) the Sustainable Development Goals. </span>
      </LeftPanel>
      <RightPanel>
        <div className="ok logo-container">
          <img className="program-logo" src={leapLogo} alt="leap logo" />
          <div className="line"></div>
          <img
            src={qualityEducation}
            className="box-image"
            alt="quality education"
          />
        </div>
        <section>
          <p className="reg-p first-par">
            New World Bank data shows that 53 percent of children in low-and
            middle-income countries suffer from learning poverty, that is the
            inability to read and understand a simple text by age 10. Even in
            countries with higher enrollment, many children leave school with
            little learning and fewer skills.
          </p>
          <p className="reg-p">
            MLIFE's{" "}
            <span
              onClick={() => setToolTipMobile(active => !active)}
              onMouseOver={() => hoverAction(true, "LEAP")}
              onMouseLeave={() => hoverAction(false, "")}
            >
              {name === "LEAP" ? (
                <Tooltip
                  buttonText="View full glossary"
                  toolTipMobile={toolTipMobile}
                  setToolTipMobile={setToolTipMobile}
                  text={tooltipText[0]}
                  hovered={hovered}
                  setHovered={setHovered}
                >
                  LEAP
                </Tooltip>
              ) : (
                <span className="special">LEAP </span>
              )}
            </span>{" "}
            program works with underserved schools to support under-resourced
            children to acquire fundamental learning skills through the power of
            storytelling and technology.
          </p>
        </section>
      </RightPanel>
    </BioContainer>
  )
}
