import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "../layout/StyledComponents"
import { PanelContainer } from "./Panel-Container"
import { Fancybox } from "../Fancy-Box"

const MainContainer = styled.div`
  padding: 72px 0px;
  background: ${colors.primary1};
  p {
    font-size: 22px;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  p:first-of-type {
    margin-top: 50px;
  }
  @media ${device.tablet} {
    img {
      width: 500px;
    }
  }
  @media ${device.laptop} {
    .video-image {
      img {
        transition: 0.2s ease-in-out;
      }
    }
    .video-image:hover {
      img {
        transform: scale(0.99);
      }
    }
    padding: 94px 0px 116px;
    > section:first-of-type {
      .first-image {
        position: relative;
        bottom: -10px;
        right: 80px;
      }

      img {
        width: 90%;
      }
    }
    > section:last-of-type {
      margin-top: 50px;
      img {
        width: 543px;
      }
    }
    .first-section {
      width: 943px;

      position: relative;
      right: 30px;
    }
    .second-section {
      width: 456px;
      margin-bottom: 70px;
    }
  }
`

export const LeapPanels = () => {
  return (
    <MainContainer>
      <PanelContainer>
        <StaticImage
          className="first-image"
          src="../../images/programs/leap/kids-smiling.webp"
          placeholder="none"
          quality={100}
          alt="children from classroom"
        />
        <div className="first-section">
          <p>
            MLIFE's Learning Enrichment & Acceleration Program (LEAP) supports
            low-progress learners from under-served and under-resourced public
            schools with the technology and human capital needed to help all
            children realize their potential.{" "}
          </p>
          <p>
            Through the Newspapers in Education Initiative (NIE), schools
            accepted into the LEAP program receive a financial grant to pay
            their annual subscription fees for local and international
            periodicals, such as the New York Times, to bring real-world
            knowledge right into their classrooms.
          </p>
        </div>
      </PanelContainer>
      <PanelContainer reverse>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vimeo.com/718580034"
        > */}
        <Fancybox options={{ infinite: false }}>
          <button
            data-fancybox="gallery"
            data-src="https://vimeo.com/718580034"
            backgroundFill
            style={{ background: "none", border: "none" }}
          >
            <StaticImage
              className="video-image"
              style={{ marginTop: "30px" }}
              src="../../images/programs/leap/classroom.png"
              placeholder="none"
              quality={100}
              alt="children from classroom"
            />
          </button>
        </Fancybox>
        {/* </a> */}
        <div className="second-section">
          <p>
            The LEAP program empowers teachers to move their students from
            'learning to read' to 'reading to learn.'
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vimeo.com/718580034"
          >
            <Fancybox options={{ infinite: false }}>
              <Button
                data-fancybox="gallery"
                data-src="https://vimeo.com/718580034"
                backgroundFill
                color="yellow"
              >
                Watch & learn more
              </Button>
            </Fancybox>
          </a>
        </div>
      </PanelContainer>
    </MainContainer>
  )
}
