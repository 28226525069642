import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"

const MainContainer = styled.div`
  padding: 62px 0px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.primary2};
  color: ${colors.white};
  p {
    color: ${colors.white};
    font-size: 22px;
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    .flex-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    padding: 128px 0px;
  }
`

const LeftColumn = styled.div`
  margin-bottom: 72px;

  h4 {
    margin: 0;
    color: ${colors.white};
    font-size: 50px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 55px;
  }
  p {
    width: 222px;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    margin-bottom: 0px;
  }
`

const RightColumn = styled.div`
  max-width: 340px;

  @media ${device.tablet} {
    max-width: 721px;
    .number-container {
      justify-content: space-between;
      display: flex;
    }
  }
`

const NumberPanel = styled.div`
  margin-bottom: 35px;

  span {
    font-family: ${fonts.secondary};
    font-size: 22px;
    font-weight: 700;
    border: 4px solid rgba(227, 191, 42, 0.4);
    border-radius: 100%;
    padding: 2px 13px;
  }
  p {
    width: 188px;
  }
  @media ${device.laptop} {
    p {
      width: 225px;
    }
  }
`

const BottomSection = styled.div`
  margin-top: 52px;
  @media ${device.tablet} {
    margin-top: 0px;
  }
  @media ${device.laptop} {
    margin-top: 00px;
    button {
      margin-bottom: 20px;
    }
    .bottom-container {
      margin-top: 55px;
      p {
        margin-bottom: 0px;
      }
    }
  }
`

export const Eligibility = () => {
  return (
    <MainContainer>
      <div className="flex-container container">
        <LeftColumn>
          <h4>Eligibility</h4>
          <p>LEAP selects schools based on the following criteria:</p>
        </LeftColumn>
        <RightColumn>
          <div className="number-container">
            <NumberPanel>
              <span>1</span>
              <p>Schools must be public or low-cost, non-profit schools</p>
            </NumberPanel>
            <NumberPanel>
              <span>2</span>
              <p>
                The student-
                <br />
                teacher ratio must <br />
                be above 1:50
              </p>
            </NumberPanel>
            <NumberPanel>
              <span>3</span>
              <p>
                A substantial # of the students must be low-progress learners
              </p>
            </NumberPanel>
          </div>
          <BottomSection>
            <p>
              <span style={{ fontWeight: "800" }}>Only for schools</span>{" "}
              applying to be part of the LEAP Program. Individual scholars
              should contact LEAP’s Program Director for additional requirements
              for MLIFE Scholars.{" "}
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/kwNSSwQZWjj7Lspp8"
            >
              <Button color="yellow">Apply today</Button>
            </a>
            <div className="bottom-container">
              <p style={{ fontWeight: "700" }}>Questions? Contact us.</p>
              <p>
                Grace Githongori <br />
                Program Manager, Learning Enrichment & Acceleration Program
                <br />
                leap@mlifefoundation.org
              </p>
            </div>
          </BottomSection>
        </RightColumn>
      </div>
    </MainContainer>
  )
}
